<template>
    <div>
        <Navbar :page="`Treinamento ${ form.nome ? form.nome : '' }`" link="/treinamentos" nameLink="Gestão de Treinamentos" />

        <nav aria-label="Progress" class="">
            <ol role="list" class="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0">
                <li v-for="(aba, index) in abas" :key="index" class="relative md:flex md:flex-1 block"  :class="{'bg-yellow-400': aba.visible, 'bg-blue-500': aba.semNumero}" 
                :style="{ 
                    'background-color': aba.visible ? $store.state.colorPrincipal : aba.semNumero ? $store.state.colorSecundaria : '', 
                    'color': aba.visible ? invertColor($store.state.colorPrincipal, true) : aba.semNumero ? invertColor($store.state.colorSecundaria, true) : '' 
                }
                "
                @click="setAba(index)"
                >
                    <a  href="#" class="flex items-center px-6 py-4 text-sm font-medium " aria-current="step">
                        <span v-if="!aba.semNumero" class="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-black-600">
                            <span v-show="!aba.visible" class="text-black-600">{{index + 1}}</span>
                            <svg v-show="aba.visible" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                            </svg>
                        </span>
                        <span class="ml-4 text-sm font-medium text-black-600" :class="{'text-white': aba.semNumero}">{{aba.nome}}</span>
                    </a>
                </li> 

            </ol>
        </nav>

        <div class="col-span-12 flex flex-col bg-gray-200 p-5" v-show="form._id && !form.ativo">
            <div class="flex">
                <label for="tipo" class="block text-sm font-medium"><b>Este evento já aconteceu ou irá acontecer?</b></label>
                <label for="estatico_sim" class="ml-10">Já aconteceu</label>
                <input :class="{'opacity-50': form._id && form.ativo}" :disabled="form._id && form.ativo" type="radio" :value="true" v-model="form.estatico" name="estatico" id="estatico_sim" class="mt-1 ml-2 focus:ring-yellow-400 focus:border-yellow-400 block shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                <label for="estatico_nao" class="ml-10">Irá acontecer</label>
                <input :class="{'opacity-50': form._id && form.ativo}" :disabled="form._id && form.ativo" type="radio" :value="false" v-model="form.estatico" name="estatico" id="estatico_nao" class="mt-1 ml-2 focus:ring-yellow-400 focus:border-yellow-400 block shadow-sm sm:text-sm border-gray-300 rounded-3xl">
            </div>
            <span class="text-red-500 text-sm">Não é possível alterar o tipo do evento depois de salvo, apenas editar os dados</span>
        </div>

        <div class="mx-4 my-4 md:mx-8 md:my-8">
            <div v-show="abas[0].visible">

                <form action="#" method="POST">
                    <div class="px-5 pb-4">
                        <div class="grid grid-cols-12 gap-4">
                            <div class="col-span-12 md:col-span-3">
                                <label for="tipo" class="block text-sm font-medium"><b>1.1</b> Tipo do evento</label>
                                <input type="text" v-model="form.tipo" name="tipo" id="tipo" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                            </div>
                            <div class="col-span-12 md:col-span-3">
                                <label for="nome" class="block text-sm font-medium"><b>1.2</b> Nome do evento</label>
                                <input type="text" v-model="form.nome" name="nome" id="nome" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                            </div> 
                            <div class="col-span-12 md:col-span-3">
                                <label for="dataInicio" class="block text-sm font-medium"><b>1.3</b> Data e Hora início</label>
                                <input v-model="form.dataInicio" type="datetime-local" name="dataInicio" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"/>
                            </div>  
                            <div class="col-span-12 md:col-span-3">
                                <label for="dataFim" class="block text-sm font-medium"><b>1.4</b> Data e Hora Fim</label>
                                <input v-model="form.dataFim" type="datetime-local" name="dataFim" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"/>
                            </div>
                        </div>
                        <div class="grid grid-cols-12 gap-4 mt-4">
                            <div class="col-span-12 md:col-span-4">
                                <label for="canal" class="block text-sm font-medium"><b>1.5</b> Canal de transmissão</label>
                                <!-- @change="changeCanalTransmissao" -->
                                <select v-model="form.transmissao.canalTransmissao"  name="canal" id="canal" class="mt-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                    <option value="Presencial">Presencial</option>
                                    <option value="Online ao vivo">Online ao vivo</option>
                                    <option value="Online gravado">Online gravado</option>
                                </select>
                            </div>
                            <div v-if="form.transmissao.canalTransmissao !== 'Online gravado'" class="col-span-12 md:col-span-8">
                                <label for="linkEndereco" class="block text-sm font-medium"><b>1.6</b> Link do canal digital ou endereço físico do evento</label>
                                <input type="text" v-model="form.transmissao.linkEndereco" name="linkEndereco" id="linkEndereco" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                            </div>
                            <div v-if="form.transmissao.canalTransmissao === 'Online gravado'" class="col-span-12 md:col-span-8">
                                <label for="linkEndereco" class="block text-sm font-medium"><b>1.6</b> Links do canal digital</label>
                                <button type="button" @click="form.transmissao.links.push('')" class="text-sm text-white hover:bg-green-600 bg-green-500 rounded py-1 px-2 mt-2">Adicionar link</button>
                                <div v-for="(linkCanal, idxlink) in form.transmissao.links" :key="idxlink" class="flex">
                                    <input type="text" v-model="form.transmissao.links[idxlink]" placeholder="URL" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                                    <button type="button" @click="form.transmissao.links.splice(idxlink, 1)" class="text-xs text-white hover:bg-red-600 bg-red-500 rounded px-3 h-8 mx-4 mt-3">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="grid grid-cols-12 gap-4 mt-4">
                            <div class="col-span-12 md:col-span-4">
                                <label for="cargaHoraria" class="block text-sm font-medium"><b>1.7</b> Carga horária</label>
                                <input type="number" v-model="form.transmissao.cargaHoraria" name="cargaHoraria" id="cargaHoraria" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                            </div>
                            <div class="col-span-12 md:col-span-8">
                                <label for="palestrante" class="block text-sm font-medium"><b>1.8</b> Facilitador/ Palestrante</label>
                                <input type="text" v-model="form.transmissao.palestrante" name="palestrante" id="palestrante" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                            </div>
                        </div>
                        <div class="grid grid-cols-12 gap-4 mt-4" >
                            <div class="col-span-12 md:col-span-4" v-show="!form.estatico">
                                <label for="tituloEmail" class="block text-sm font-medium mb-4"><b>1.9</b> Personalização do email </label>

                                <label for="tituloEmail" class="block text-sm font-medium">Título do email (assunto)</label>
                                <input type="text" name="tituloEmail" id="tituloEmail" placeholder="Título do email" class=" focus:ring-yellow-400 focus:border-yellow-400 block  w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl" v-model="form.assuntoDoConvite">
                                
                                <label for="textoDoConvite" class="block text-sm font-medium mt-3">Texto do email 
                                    <svg v-tooltip="{ content: '<p class=\'w-115\'>O título e texto são preenchidos automaticamente com o titulo e texto que está no cadastro da White label, você pode customizar detalhes dele aqui sem precisar alterar diretamente no cadastro, as alterações feitas aqui valem apenas para esse treinamento.</p>', html: true, placement: 'bottom' }" class="h-6 inline" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M140,176a4,4,0,0,1-4,4,12,12,0,0,1-12-12V128a4,4,0,0,0-4-4,4,4,0,0,1,0-8,12,12,0,0,1,12,12v40a4,4,0,0,0,4,4A4,4,0,0,1,140,176ZM124,92a8,8,0,1,0-8-8A8,8,0,0,0,124,92Zm104,36A100,100,0,1,1,128,28,100.11,100.11,0,0,1,228,128Zm-8,0a92,92,0,1,0-92,92A92.1,92.1,0,0,0,220,128Z"></path></svg>
                                </label>

                                <textarea type="text" rows="10" v-model="form.textoDoConvite" name="textoDoConvite" id="textoDoConvite" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"></textarea>
                            </div>
                            <div class="col-span-12 md:col-span-8" v-show="!form.estatico">
                                <div class="grid grid-cols-12 gap-3">
                                    <div class="col-span-12 md:col-span-6">
                                        <label for="arte" class="block text-sm font-medium"><b>1.10</b> Arte do evento (card de divulgação)</label>
                                        <small class="text-xs font-medium">Tamanho recomendado 800x600</small>
                                        <vue-dropzone ref="doc" id="doc" class="w-full mt-2" :options="dropzoneOptions" v-on:vdropzone-success="update">
                                        </vue-dropzone>
                                    </div>
                                    <div class="col-span-12 md:col-span-6">
                                        <img v-if="this.form.arte && this.form.arte.mimetype" class="h-auto" :src="`${this.url_api}/upload?mimetype=${this.form.arte.mimetype}&filename=${this.form.arte.filename}&folder=empresas`" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-span-12" >
                                <h2 class="text-xl font-semibold mb-1">Anexar evidências do evento </h2>
                                <vue-dropzone ref="docEvidencias" id="docEvidencias" class="w-full" :options="dropzoneOptions" v-on:vdropzone-success="(file, response) => updateDocumentoEvidencias(file, response, 'Evidencias')"></vue-dropzone>
                                

                                <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                    <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                            <table class="min-w-full divide-y divide-gray-200">
                                                <thead class="bg-gray-50">
                                                    <tr>
                                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                                                        Arquivo
                                                    </th>
                                                    <th  scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                                                        Opção
                                                    </th>
                                                    </tr>
                                                </thead>
                                                <tbody class="bg-white divide-y divide-gray-200">
                                                    <tr v-for="(item, idx) in this.form.evidencias" :key="item._id" v-show="item.tipoEvidencia === 'Evidencias'">
                                                    <td class="px-6 py-4 whitespace-nowrap">
                                                        <div class="flex items-center">
                                                        <div class="text-sm text-gray-500">
                                                            {{item.originalname ? item.originalname : ''}}
                                                        </div>
                                                        </div>
                                                    </td>
                                                    <td  class="px-6 py-4 whitespace-nowrap">
                                                        <div class="flex items-center">
                                                        <div class="text-sm text-blue-500">
                                                            <a target="_blank" :href="`${url_api}/upload?mimetype=${item.mimetype}&filename=${item.filename}&folder=empresas`">Visualizar</a>
                                                            <button
                                                            type="button"
                                                            @click="form.evidencias.splice(idx, 1)" 
                                                            class="inline-flex justify-center py-1 px-2 mx-3 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                                                            Remover
                                                            </button>
                                                        </div>
                                                        </div>
                                                    </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-span-12">
                                <button @click="$modal.show('documentoMaterial')" type="button" class="mb-3 inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-300 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-400" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }">
                                    Adicionar material de apoio
                                </button>
                                <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                    <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                            <table class="min-w-full divide-y divide-gray-200">
                                                <thead class="bg-gray-50">
                                                    <tr>
                                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                                                        Arquivo
                                                    </th>
                                                    <th  scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                                                        Opção
                                                    </th>
                                                    </tr>
                                                </thead>
                                                <tbody class="bg-white divide-y divide-gray-200">
                                                    <tr v-for="(item, idx) in form.documentos" :key="item._id">
                                                    <td class="px-6 py-4 whitespace-nowrap">
                                                        <div class="flex items-center">
                                                        <div class="text-sm text-gray-500">
                                                            {{item.originalname ? item.originalname : ''}}
                                                        </div>
                                                        </div>
                                                    </td>
                                                    <td  class="px-6 py-4 whitespace-nowrap">
                                                        <div class="flex items-center">
                                                        <div class="text-sm text-blue-500">
                                                            <a target="_blank" :href="`${url_api}/upload?mimetype=${item.mimetype}&filename=${item.filename}&folder=empresas`">Visualizar</a>
                                                            <button
                                                            type="button"
                                                            @click="form.documentos.splice(idx, 1)" 
                                                            class="inline-flex justify-center py-1 px-2 mx-3 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                                                            Remover
                                                            </button>
                                                        </div>
                                                        </div>
                                                    </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div v-show="abas[1].visible">
                <form action="#" method="POST">
                    <div class="grid grid-cols-12 gap-4">
                        <div class="col-span-12" v-show="this.form.estatico">
                            <h2 class="text-xl font-semibold mb-1"> Adicionar evidências (lista de participantes em pdf, docx, xlsx, etc.) </h2>
                            <vue-dropzone ref="docParticipantes" id="docParticipantes" class="w-full" :options="dropzoneOptions" v-on:vdropzone-success="(file, response) => updateDocumentoEvidencias(file, response, 'Participantes')"></vue-dropzone>
                            

                            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                    <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                        <table class="min-w-full divide-y divide-gray-200">
                                            <thead class="bg-gray-50">
                                                <tr>
                                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                                                    Arquivo
                                                </th>
                                                <th  scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                                                    Opção
                                                </th>
                                                </tr>
                                            </thead>
                                            <tbody class="bg-white divide-y divide-gray-200">
                                                <tr v-for="(item, idx) in this.form.evidencias" :key="item._id" v-show="item.tipoEvidencia === 'Participantes'">
                                                <td class="px-6 py-4 whitespace-nowrap">
                                                    <div class="flex items-center">
                                                    <div class="text-sm text-gray-500">
                                                        {{item.originalname ? item.originalname : ''}}
                                                    </div>
                                                    </div>
                                                </td>
                                                <td  class="px-6 py-4 whitespace-nowrap">
                                                    <div class="flex items-center">
                                                    <div class="text-sm text-blue-500">
                                                        <a target="_blank" :href="`${url_api}/upload?mimetype=${item.mimetype}&filename=${item.filename}&folder=empresas`">Visualizar</a>
                                                        <button
                                                        type="button"
                                                        @click="form.evidencias.splice(idx, 1)" 
                                                        class="inline-flex justify-center py-1 px-2 mx-3 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                                                        Remover
                                                        </button>
                                                    </div>
                                                    </div>
                                                </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-span-12 md:col-span-5">
                            <label for="buscaParmsTipo" class="block text-sm font-medium">Tipo</label>
                            <select v-model="buscaParms.tipo" name="buscaParmsTipo" id="buscaParmsTipo" class="mt-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                <option value="Pessoa na Empresa">Pessoa na Empresa</option>
                                <option value="Agente de Tratamento">Agente de Tratamento</option>
                                <option value="Outro">Outro</option>
                            </select>
                            <div v-if="buscaParms.tipo !== 'Outro'" class="grid grid-cols-12 gap-2 mt-2">
                                <div class="col-span-12 md:col-span-9">
                                    <label class="block text-sm font-medium">{{ buscaParms.tipo === 'Pessoa na Empresa' ? 'Setor' : 'Tipo Agente de tratamento' }}</label>
                                    <select v-if="buscaParms.tipo === 'Pessoa na Empresa'" v-model="buscaParms.setor" class="mt-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                        <option default :value="null">Todos os setores</option>
                                        <option v-for="s in setores" :key="s._id" :value="s._id">{{ s.nome }}</option>
                                    </select>
                                    <select v-if="buscaParms.tipo === 'Agente de Tratamento'" v-model="buscaParms.agente" class="mt-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                        <option default :value="null">Todos os tipos</option>
                                        <option v-for="agenteTratamento in agentesTratamento" :key="agenteTratamento._id" :value="agenteTratamento._id">{{ agenteTratamento.nome }}</option>
                                    </select>
                                </div>
                                <div class="col-span-12 md:col-span-3">
                                    <label class="block text-sm font-medium">Buscar</label>
                                    <button @click="startBusca()" type="button" class="inline-flex justify-center w-full py-2 px-2 mt-1 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                                        Buscar
                                    </button>
                                </div>
                            </div>
                            <div v-if="buscaParms.tipo !== 'Outro'" class="mt-2">
                                <span class="font-semibold text-sm">Total: {{total}} <span @click="selecionarTodos()" class="text-green-700 underline cursor-pointer ml-1">Selecionar todos</span> <span class="text-blue-700 ml-1">Selecionados: {{selecionadosBusca.length}}</span></span>
                            </div>
                            <div v-if="busca.length && buscaParms.tipo !== 'Outro'" class="h-96 overflow-auto px-3 py-2 bg-gray-200 rounded-lg mt-2">
                                <label v-for="item in busca" :key="item._id" class="block text-sm font-medium my-1">
                                    <input type="checkbox" v-model="item.check" @change="changeBusca(item)" class="rounded-sm" :id="item._id" v-if="!item.adicionado">
                                    <input type="checkbox" v-model="sempreMarcado" class="rounded-sm text-gray-400" :id="item._id" disabled v-else>
                                    <span :class="`ml-2 ${item.adicionado ? 'text-gray-500': ''}`">
                                        {{ item.nome }}<span v-if="item.email"> - {{ item.email }} ({{ item.setor ? item.setor.nome : 'Sem setor' }} / {{ item.funcao ? item.funcao.nome : 'Sem Função' }})</span>
                                    </span>
                                </label>
                            </div>
                            <button v-if="total > 0 && buscaParms.tipo !== 'Outro'" @click="addConvidados()" type="button" class="inline-flex justify-center py-1 px-2 mt-3 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-green-700">
                                Adicionar convidados
                            </button>
                            <div v-if="buscaParms.tipo === 'Outro'" class="mt-2">
                                <label for="outroNome" class="block text-sm font-medium"> Nome </label>
                                <input type="text" v-model="outro.nome" name="outroNome" id="outroNome" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                                <label for="outroEmail" class="block text-sm font-medium mt-2"> Email </label>
                                <input type="text" v-model="outro.email" name="outroEmail" id="outroNome" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                            </div>
                            <button v-if="buscaParms.tipo === 'Outro'" @click="addConvidado()" type="button" class="inline-flex justify-center py-1 px-2 mt-3 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-green-700">
                                Adicionar convidado
                            </button>
                        </div>
                        <div class="col-span-12 md:col-span-7">
                            <h3 class="text-lg font-medium">Convidados</h3>
                            <span class="font-semibold text-green-600 text-sm">Total: {{totalConvidados}} </span><span @click="removerConvidados" v-if="selecionadosConvidados.length" class="text-sm font-semibold text-red-700 underline cursor-pointer ml-1">Remover selecionados</span> <span v-if="selecionadosConvidados.length" class="text-sm font-semibold text-blue-700 ml-1">Selecionados: {{selecionadosConvidados.length}}</span>
                            <div v-if="convidados.length" class="h-120 overflow-auto mt-2">
                                <div class="py-2 align-middle inline-block min-w-full">
                                    <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                        <table class="min-w-full divide-y divide-gray-200">
                                            <thead class="bg-gray-50">
                                                <tr>
                                                    <th scope="col" class="px-1 py-2 text-left text-xs font-medium text-gray-500 tracking-wider cursor-pointer">
                                                        <svg @click="selecionarTodosConvidados()" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                        </svg>
                                                    </th>
                                                    <th scope="col" class="px-3 py-2 text-left text-xs font-medium text-gray-500 tracking-wider">
                                                        Nome
                                                    </th>
                                                    <th scope="col" class="px-3 py-2 text-left text-xs font-medium text-gray-500 tracking-wider">
                                                        Email
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody class="bg-white divide-y divide-gray-200">
                                                <tr v-for="item in convidados" :key="item._id">
                                                    <td v-if="!$store.state.user.cliente" class="px-1 py-2">
                                                        <div class="flex items-center">
                                                            <input :checked="selecionadosConvidados.map(el => el._id).indexOf(item._id) > -1" @change="(e) => selecionadosControl(item, e)" type="checkbox" class="rounded-sm">
                                                        </div>
                                                    </td>
                                                    <td class="px-3 py-2">
                                                        <div class="flex items-center">
                                                            <div class="text-sm text-gray-500">
                                                                {{item.nome ? item.nome : ''}}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="px-3 py-2">
                                                        <div class="flex items-center">
                                                            <div class="text-sm text-gray-500">
                                                                {{item.email ? item.email : ''}}
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <h4 v-else class="text-base">Nenhum convidado no treinamento</h4>
                        </div>
                    </div>
                    <div class="px-5 pb-4">
                    </div>
                </form>
            </div>
            <div v-show="abas[2].visible">
                <form action="#" method="POST">
                    <div class="grid grid-cols-12 gap-4">

                        <div class="col-span-12" v-show="this.form.estatico">
                            <h2 class="text-xl font-semibold mb-1"> Adicionar evidências (avaliações em pdf, docx, xlsx, etc.) </h2>
                            <vue-dropzone ref="docAvaliacao" id="docAvaliacao" class="w-full" :options="dropzoneOptions" v-on:vdropzone-success="(file, response) => updateDocumentoEvidencias(file, response, 'Avaliacao')"></vue-dropzone>
                            

                            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                    <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                        <table class="min-w-full divide-y divide-gray-200">
                                            <thead class="bg-gray-50">
                                                <tr>
                                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                                                    Arquivo
                                                </th>
                                                <th  scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                                                    Opção
                                                </th>
                                                </tr>
                                            </thead>
                                            <tbody class="bg-white divide-y divide-gray-200">
                                                <tr v-for="(item, idx) in this.form.evidencias" :key="item._id" v-show="item.tipoEvidencia === 'Avaliacao'">
                                                <td class="px-6 py-4 whitespace-nowrap">
                                                    <div class="flex items-center">
                                                    <div class="text-sm text-gray-500">
                                                        {{item.originalname ? item.originalname : ''}}
                                                    </div>
                                                    </div>
                                                </td>
                                                <td  class="px-6 py-4 whitespace-nowrap">
                                                    <div class="flex items-center">
                                                    <div class="text-sm text-blue-500">
                                                        <a target="_blank" :href="`${url_api}/upload?mimetype=${item.mimetype}&filename=${item.filename}&folder=empresas`">Visualizar</a>
                                                        <button
                                                        type="button"
                                                        @click="form.evidencias.splice(idx, 1)" 
                                                        class="inline-flex justify-center py-1 px-2 mx-3 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                                                        Remover
                                                        </button>
                                                    </div>
                                                    </div>
                                                </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="col-span-12" v-show="!this.form.estatico">
                            <label for="naoContemAvaliacao" class="block text-sm font-medium">
                                <input type="checkbox" v-model="form.naoContemAvaliacao" class="rounded-sm" id="naoContemAvaliacao">
                                <span class="ml-2">
                                    Este evento não terá avaliação
                                </span>
                            </label>
                        </div>
                        <div v-if="!form.naoContemAvaliacao" class="col-span-12 md:col-span-4" v-show="!this.form.estatico">
                            <label class="block text-sm font-medium"><b>3.1</b> Avaliação</label>
                            <select v-model="form.avaliacao" class="mt-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                <option v-for="a in avaliacoes" :key="a._id" :value="a._id">{{ a.nome }}</option>
                            </select>
                        </div>
                        <div v-if="!form.naoContemAvaliacao" class="col-span-12 md:col-span-4" v-show="!this.form.estatico">
                            <label class="block text-sm font-medium"><b>3.2</b> Programar envio de avaliações</label>
                            <select v-model="form.envioAvaliacao.tipo" class="mt-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                <option value="Logo após a conclusão do evento"> Logo após a conclusão do evento </option>
                                <option value="Programar para data específica"> Programar para data específica </option>
                            </select>
                        </div>
                        <div v-show="!form.naoContemAvaliacao && form.envioAvaliacao.tipo === 'Programar para data específica' && !this.form.estatico" class="col-span-12 md:col-span-4">
                            <label class="block text-sm font-medium"><b>3.3</b> Programar envio de avaliações (data)</label>
                            <input v-model="form.envioAvaliacao.data" type="datetime-local" name="dataInicio" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"/>
                        </div>
                    </div>

                    
                </form>
            </div>
            <div v-show="abas[3].visible">
                <form action="#" method="POST">
                    <div class="grid grid-cols-12 gap-4">

                        <div class="col-span-12" v-show="this.form.estatico">
                            <h2 class="text-xl font-semibold mb-1"> Adicionar evidências (certificados em pdf, docx, xlsx, etc.) </h2>
                            <vue-dropzone ref="docCertificado" id="docCertificado" class="w-full" :options="dropzoneOptions" v-on:vdropzone-success="(file, response) => updateDocumentoEvidencias(file, response, 'Certificado')"></vue-dropzone>
                            

                            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                    <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                        <table class="min-w-full divide-y divide-gray-200">
                                            <thead class="bg-gray-50">
                                                <tr>
                                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                                                    Arquivo
                                                </th>
                                                <th  scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                                                    Opção
                                                </th>
                                                </tr>
                                            </thead>
                                            <tbody class="bg-white divide-y divide-gray-200">
                                                <tr v-for="(item, idx) in this.form.evidencias" :key="item._id" v-show="item.tipoEvidencia === 'Certificado'">
                                                <td class="px-6 py-4 whitespace-nowrap">
                                                    <div class="flex items-center">
                                                    <div class="text-sm text-gray-500">
                                                        {{item.originalname ? item.originalname : ''}}
                                                    </div>
                                                    </div>
                                                </td>
                                                <td  class="px-6 py-4 whitespace-nowrap">
                                                    <div class="flex items-center">
                                                    <div class="text-sm text-blue-500">
                                                        <a target="_blank" :href="`${url_api}/upload?mimetype=${item.mimetype}&filename=${item.filename}&folder=empresas`">Visualizar</a>
                                                        <button
                                                        type="button"
                                                        @click="form.evidencias.splice(idx, 1)" 
                                                        class="inline-flex justify-center py-1 px-2 mx-3 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                                                        Remover
                                                        </button>
                                                    </div>
                                                    </div>
                                                </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-span-12" v-show="!this.form.estatico">
                            <label for="naoIncluiCertificado" class="block text-sm font-medium">
                                <input type="checkbox" v-model="form.certificado.naoIncluiCertificado" class="rounded-sm" id="naoIncluiCertificado">
                                <span class="ml-2">
                                    Este evento não terá certificado
                                </span>
                            </label>
                        </div>
                        <div v-if="!form.certificado.naoIncluiCertificado" class="col-span-12 md:col-span-4" v-show="!this.form.estatico">
                            <label for="quemAssina" class="block text-sm font-medium"><b>4.1</b> Quem assina</label>
                            <input type="text" v-model="form.certificado.quemAssina" name="quemAssina" id="quemAssina" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                        </div>
                        <div v-if="!form.certificado.naoIncluiCertificado" class="col-span-12 md:col-span-4" v-show="!this.form.estatico">
                            <label for="cargoQuemAssina" class="block text-sm font-medium"><b>4.2</b> Cargo de quem assina</label>
                            <input type="text" v-model="form.certificado.cargoQuemAssina" name="cargoQuemAssina" id="cargoQuemAssina" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                        </div>
                    </div>
                    <div v-if="!form.certificado.naoIncluiCertificado" class="grid grid-cols-12 gap-4 mt-4" v-show="!this.form.estatico">
                        <div class="col-span-12 md:col-span-4">
                            <label class="block text-sm font-medium"><b>4.3</b> Programar envio de certificado</label>
                            <select v-model="form.certificado.envioCertificado.tipo" class="mt-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                <option value="Logo após a conclusão do teste"> Logo após a conclusão da avaliação </option>
                                <option value="Programar para data específica"> Programar para data específica </option>
                            </select>
                        </div>
                        <div v-show="form.certificado.envioCertificado.tipo === 'Programar para data específica'" class="col-span-12 md:col-span-4">
                            <label class="block text-sm font-medium"><b>4.4</b> Programar envio de certificado (data)</label>
                            <input v-model="form.certificado.envioCertificado.data" type="datetime-local" name="dataInicio" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"/>
                        </div>
                    </div>
                    
                    <div class="grid grid-cols-12 gap-3 mt-4">
                        <div class="col-span-12 md:col-span-12">
                            <label for="backgroundCertificado" class="block text-sm font-medium">
                                <b>4.4</b> Imagem de background do certificado (Opcional) Resolução recomendada 3000x2000
                                <small></small>
                            </label>
                            <vue-dropzone 
                                ref="backgroundCertificado" 
                                id="backgroundCertificado" 
                                class="w-full mt-2" 
                                :options="dropzoneOptionsCertificado" 
                                v-on:vdropzone-success="updateBackgroundCertificado"
                                v-on:vdropzone-removed-file ="removeBackgroundCertificado"
                            >
                            </vue-dropzone>
                            <button type="button" @click="removeBackgroundCertificado">Voltar ao fundo padrão</button>
                        </div>
                        <div class="col-span-12 md:col-span-12 flex flex-col" >
                            <label for="backgroundCertificado" class="block text-sm font-medium">
                                Preview
                            </label>
                            <div>
                                <img 
                                    v-if="form.backgroundCertificado && form.backgroundCertificado.mimetype"
                                    :style="`width: ${w}px; height: ${h}px;`"
                                    class="absolute h-auto border border-gray-500" 
                                    :src="`${url_api}/upload?mimetype=${form.backgroundCertificado.mimetype}&filename=${form.backgroundCertificado.filename}&folder=empresas`" 
                                    alt="Imagem de background do certificado"
                                />
                                <img 
                                    v-else
                                    :style="`width: ${w}px; height: ${h}px;`"
                                    class="absolute h-auto border border-gray-500"
                                    :src="`${url_api}/background-default/background-sem-logo.png`" 
                                    alt="Imagem de background do certificado"
                                />

                                <div class="relative border-4 bg-blue-400 border-blue-400 font-bold text-center align-middle justify-items-center place-content-center" 
                                    :style="`left: ${(w / 2) - ((60 * p) / 2)}px; top: ${40 * p}px; width: ${60 * p}px; height: ${60 * p}px;`">
                                    <span>
                                        Logo
                                    </span>
                                </div>

                                <div class="relative font-bold text-center"
                                    :style="`width: ${720 * p}px; top: ${(90-40) * p}px;font-size: ${36 * p}px`">
                                    <span>
                                        CERTIFICADO
                                    </span>
                                </div>

                                <div class="relative font-bold text-center"
                                    :style="`width: ${720 * p}px; top: ${(130-90) * p}px;font-size: ${16 * p}px;color:#AF8865`">
                                    <span>
                                        CERTIFICO QUE:
                                    </span>
                                </div>

                                <div class="relative font-bold text-center"
                                    :style="`width: ${720 * p}px; top: ${(170-130) * p}px;font-size: ${48 * p}px;`">
                                    <span>
                                        Nome Do Participante
                                    </span>
                                </div>

                                <div class="relative text-center"
                                    :style="`left:${(w / 2) - ((500 * p) / 2)}px ;width: ${500 * p}px; top: ${(250-210) * p}px;font-size: ${13 * p}px;`">
                                    <span>
                                        Participou, durante o mês de Novembro de XXXX, do evento "XXXXXXX", cuja carga horária se perfez em XX hora(s).
                                    </span>
                                </div>

                                <div class="relative text-center"
                                    :style="`width: ${720 * p}px; top: ${(335-250) * p}px;font-size: ${14 * p}px;`">
                                    <span>
                                        XX de Novembro de 2024
                                    </span>
                                </div>

                                <div class="relative text-center"
                                    :style="`width: ${720 * p}px; top: ${(365-250) * p}px;font-size: ${16 * p}px;`">
                                    <span>
                                        Nome do DPO
                                    </span>
                                </div>

                                <div class="relative font-bold text-center"
                                    :style="`width: ${720 * p}px; top: ${(405-300) * p}px;font-size: ${12 * p}px;`">
                                    <span>
                                        __________________________
                                    </span>
                                </div>

                                <div class="relative font-bold text-center"
                                    :style="`width: ${720 * p}px; top: ${(420-315) * p}px;font-size: ${13 * p}px;color:#AF8865`">
                                    <span>
                                        Cargo
                                    </span>
                                </div>

                                
                            </div>

                        </div>
                    </div>
                    
                </form>
            </div>
            <div class="grid grid-cols-2 mt-14">
                <div class="px-5 py-3 text-left sm:px-6">
                    <button @click="$router.push({ path: '/treinamentos'})" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                        Voltar
                    </button>
                </div>
                <div class="px-5 py-3 text-right sm:px-6">
                    <button @click="save" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-300 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-400" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }">
                        Salvar
                    </button>
                    <button v-if="form.status === 'Em andamento'" @click="save('finalizado')" type="button" class="inline-flex justify-center mx-2 py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-500 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-400" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }">
                        Encerrar evento
                    </button>
                </div>
            </div>
        </div>
        <modal name="documentoMaterial" :width="800" :height="'auto'" :adaptive="true" :reset="true">
        <div class="px-3 py-2 overflow-y-auto h-full">
            <h2 class="text-xl font-semibold mb-1"> Adicionar material de apoio </h2>
            <vue-dropzone ref="docDocumento" id="docDocumento" class="w-full" :options="dropzoneOptions" v-on:vdropzone-success="updateDocumento"></vue-dropzone>
        </div>
        </modal>
    </div>
</template>

<script>
import moment from "moment";
import vueDropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

export default {
    components: {
        vueDropzone,
    },
    data() {
        return {
            //preview values

            w: 3000 / 5,
            h: 2121 / 5,
            p: 4.167 / 5, //5x menor

            route: 'treinamentos',
            idx: 0,
            sempreMarcado: true,
            outro: { nome: "", email: "" },
            form: {
                nome: '',
                tipo: '',
                dataInicio: moment().add(1, 'days').format("YYYY-MM-DD HH:mm"),
                dataFim: moment().add(2, 'days').format("YYYY-MM-DD HH:mm"),
                transmissao: {
                    canalTransmissao: '',
                    linkEndereco: '',
                    links: [],
                    cargaHoraria: '',
                    palestrante: '',
                },
                assuntoDoConvite: '',
                textoDoConvite: '',
                arte: null,
                envioAvaliacao: {
                    tipo: 'Logo após a conclusão do evento',
                    data: moment().add(1, 'days').format("YYYY-MM-DD HH:mm")
                },
                certificado: {
                    naoIncluiCertificado: false,
                    quemAssina: '',
                    cargoQuemAssina: '',
                    envioCertificado: {
                        tipo: 'Logo após a conclusão do teste',
                        data: moment().add(1, 'days').format("YYYY-MM-DD HH:mm")
                    },
                },
                documentos: [],
                evidencias: [],
                estatico: false,
            },
            avaliacoes: [],
            convidados: [],
            selecionadosConvidados: [],
            totalConvidados: 0,
            certificado: true,
            setores: [],
            agentesTratamento: [],
            busca: [],
            selecionadosBusca: [],
            total: 0,
            buscaParms: {
                tipo: "Pessoa na Empresa",
                setor: null,
                agente: null,
            },
            dropzoneOptions: this.$http.getDropzoneConfig(
                `v1/upload`,
                'post',
            {
                maxFiles: 1,
                addRemoveLinks: true,
                capture: true,
                dictDefaultMessage: 'Clique ou arraste o arquivo aqui',
                dictRemoveFile: 'Remover'
            }),
            dropzoneOptionsCertificado: this.$http.getDropzoneConfig(
                `v1/upload`,
                'post',
            {
                maxFiles: 1,
                addRemoveLinks: true,
                capture: true,
                dictDefaultMessage: 'Clique ou arraste uma imagem aqui',
                dictRemoveFile: 'Remover',
                acceptedFiles: 'image/*'
            }),
            abas: [
                {
                    nome: 'Cadastrar Evento',
                    visible: true,
                },
                {
                    nome: 'Convidar Participantes',
                    visible: false,
                },
                {
                    nome: 'Avaliação',
                    visible: false,
                },
                {
                    nome: 'Certificados',
                    visible: false,
                },
            ],
        }
    },
    methods: {
        setAba(index) {
            this.abas.forEach((a,i) => {
                a.visible = false;
                if (i === index) a.visible = true;
            });
        },
        async save(status) {
            if(!this.form.nome) return this.$vToastify.error("Digite o nome do treinamento");

            if(status === 'finalizado') {
                this.form.status = 'Encerrado';
                this.form.finalizado = true;
            }

            const method = this.form._id ? 'put' : 'post';
            const req = await this.$http[method](`/v1/${this.route}`, this.form);
            if (req.data.success) {
                this.$vToastify.success("Salvo com sucesso!");
                this.$router.push({path: '/treinamentos'});
            } else {
                this.$vToastify.error(req.data.err);
            }
        },
        async update(file, response) {
            this.form.arte = response.file;
        },
        async updateBackgroundCertificado(file, response) {
            this.form.backgroundCertificado = response.file;
        },
        async removeBackgroundCertificado(){
            this.form.backgroundCertificado = null;
        },
        async updateDocumento(file, response) {
            this.$refs.docDocumento.removeFile(file);
            this.form.documentos.push(response.file);
            this.$modal.hide('documentoMaterial');
        },
        async updateDocumentoEvidencias(file, response, tipo) {
            this.$refs['doc' + tipo].removeFile(file);
            response.file.tipoEvidencia = tipo;
            this.form.evidencias.push(response.file);
        },
        async removerConvidados(){
            const id = this.$route.params.id ? this.$route.params.id.match(/^[0-9a-fA-F]{24}$/)[0] : '';
            const req = await this.$http.post(`/v1/${this.route}/convidados/removerConvidados`, { _id: id, selecionados: this.selecionadosConvidados });
            if (req.data.success) {
                this.$vToastify.success("Removidos com com sucesso!");
                this.selecionadosConvidados = [];
                this.convidados = req.data.convidados;
                this.totalConvidados = req.data.total;
            } else {
                this.$vToastify.error(req.data.err);
            }
            this.marcarAdicionados();
        },
        selecionadosControl(item, e){
            if (this.selecionadosConvidados.map(el => el._id).indexOf(item._id) > -1 && !e.target.checked) {
                this.selecionadosConvidados.splice(this.selecionadosConvidados.map(el => el._id).indexOf(item._id), 1);
            } else {
                this.selecionadosConvidados.push(item);
            }
        },
        selecionarTodosConvidados(){
            for(let i = 0; i < this.convidados.length; i++){
                const item = this.convidados[i];
                if(this.selecionadosConvidados.map(el => el._id).indexOf(item._id) ==! -1) this.selecionadosConvidados.splice(this.selecionadosConvidados.map(el => el._id).indexOf(item._id), 1);
                else this.selecionadosConvidados.push(item);
            }
        },
        async addConvidado(){
            const id = this.$route.params.id ? this.$route.params.id.match(/^[0-9a-fA-F]{24}$/)[0] : '';
            const req = await this.$http.post(`/v1/${this.route}/convidados/addConvidado`, { _id: id, form: this.outro });
            if (req.data.success) {
                this.$vToastify.success("Adicionado com sucesso");
                this.selecionadosBusca = [];
                this.convidados = req.data.convidados;
                this.totalConvidados = req.data.total;
                this.outro = { nome: "", email: "" };
                this.startBusca();
            } else {
                this.$vToastify.error(req.data.err);
            }
        },
        async addConvidados(){
            const id = this.$route.params.id ? this.$route.params.id.match(/^[0-9a-fA-F]{24}$/)[0] : '';
            const req = await this.$http.post(`/v1/${this.route}/convidados/addConvidados`, { _id: id, selecionados: this.selecionadosBusca });
            if (req.data.success) {
                this.$vToastify.success(`${req.data.addCount} adicionados com com sucesso`);
                this.selecionadosBusca = [];
                this.convidados = req.data.convidados;
                this.totalConvidados = req.data.total;

                this.form.envioAvaliacao.enviado = false
                this.form.certificado.enviado = false
                this.startBusca();
            } else {
                this.$vToastify.error(req.data.err);
            }
        },
        changeBusca(item){
            if(item.check) {
                this.selecionadosBusca.push({ id: item._id, nome: item.nome, email: item.email });
            }else {
                const index = this.selecionadosBusca.map(el => el.id).indexOf(item._id);
                if(index !== -1){
                    this.selecionadosBusca.splice(index, 1);
                }
            }
        },
        selecionarTodos() {
            const acao = this.selecionadosBusca.length > 0 ? 'removerTudo' : 'adicionarTudo';

            if (acao === 'removerTudo') this.selecionadosBusca = [];

            if (acao === 'adicionarTudo') {
                for(let i = 0; i < this.busca.length; i++){
                    const item = this.busca[i];
                    item.check = true;
                    this.selecionadosBusca.push({ id: item._id, nome: item.nome, email: item.email });
                }
            }
        },
        marcarAdicionados() {
            for (let i = 0; i < this.busca.length; i++) {
                const item = this.busca[i];
                const indiceAdicionado = this.convidados.findIndex((convidado) => convidado.id === item._id);

                if (indiceAdicionado !== -1) this.busca[i].adicionado = true;
                else this.busca[i].adicionado = false;
            }
        },
        async startBusca(){
            if(this.selecionadosBusca.length > 0) {
                return this.$vToastify.error("Adicione os selecionados aos convidados, antes de realizar uma nova busca!");
            }
            if(this.buscaParms.tipo === 'Pessoa na Empresa') {
                const reqbusca = await this.$http.post(`/v1/pessoas/list`, { all: true, setor: this.buscaParms.setor });
                this.busca = reqbusca.data.data;
                this.total = reqbusca.data.total;
            }
            if(this.buscaParms.tipo === 'Agente de Tratamento') {
                const reqbusca = await this.$http.post(`/v1/fornecedores/list`, { all: true, agente: this.buscaParms.agente });
                this.busca = reqbusca.data.data;
                this.total = reqbusca.data.total;
            }
            this.marcarAdicionados();
        },
        setBackgroundCertificadoDropzone() {
            const dropzoneInstance = this.$refs.backgroundCertificado.dropzone;

            const fileUrl = `${this.url_api}/upload?mimetype=${this.form.backgroundCertificado.mimetype}&filename=${this.form.backgroundCertificado.filename}&folder=${this.form.backgroundCertificado.folder || 'empresas'}`;

            const mockFile = {
                name: this.form.backgroundCertificado.originalname,
                size: this.form.backgroundCertificado.size,
                type: this.form.backgroundCertificado.mimetype
            };

            dropzoneInstance.emit('addedfile', mockFile);
            dropzoneInstance.emit('thumbnail', mockFile, fileUrl);


            dropzoneInstance.emit('complete', mockFile);
            dropzoneInstance.files.push(mockFile);
        }
        // changeCanalTransmissao(){
        //     if(this.form.transmissao.canalTransmissao === 'Online gravado') {
        //         this.form.textoDoConvite = 'Olá #nomedapessoa#, você foi convidado (a) a participar do evento #nomedoevento#, que será realizado no dia #data#, às #hora#. É importante você perceber que o evento será #canaltransmissao# e está disponível no(s) link(s) abaixo.\n\nPara acessar o conteúdo clique no(s) link(s) abaixo (ou copie e cole em seu navegador preferido).\n\n#LINKS\nContamos com sua presença!!';
        //     }
        // }
    },
    async beforeMount() {

        const id = this.$route.params.id ? this.$route.params.id.match(/^[0-9a-fA-F]{24}$/)[0] : '';

        const listSetores = await this.$http.post(`/v1/setores/list`, { all: true});
        this.setores = listSetores.data.data;

        const listAgentesTratamento = await this.$http.post(`/v1/agentes/list`, { all: true});
        this.agentesTratamento = listAgentesTratamento.data.data;

        const listAvaliacoes = await this.$http.post(`/v1/avaliacoes/list`, { all: true});
        this.avaliacoes = listAvaliacoes.data.data;
        
        const listConvidados = await this.$http.post(`/v1/${this.route}/convidados/list`, { all: true, treinamento: id });
        this.convidados = listConvidados.data.data;
        this.totalConvidados = listConvidados.data.total;

        

        this.startBusca();

        if (id) {
            const req = await this.$http.get(`/v1/${this.route}/${id}`);
            if(req.data.ativo){
                this.form = req.data;
                
                // if (this.form.backgroundCertificado) {
                //     this.setBackgroundCertificadoDropzone(this.form.backgroundCertificado);
                // }

                if (req.data.dataInicio) {
                    this.form.dataInicio = moment(req.data.dataInicio).format("YYYY-MM-DD HH:mm");
                }

                if (req.data.dataFim) {
                    this.form.dataFim = moment(req.data.dataFim).format("YYYY-MM-DD HH:mm");
                }

                if (req.data.envioAvaliacao.data) {
                    this.form.envioAvaliacao.data = moment(req.data.envioAvaliacao.data).format("YYYY-MM-DD HH:mm");
                }
                
                if (req.data.certificado.envioCertificado.data) {
                    this.form.certificado.envioCertificado.data =  moment(req.data.certificado.envioCertificado.data).format("YYYY-MM-DD HH:mm");
                }
            }else {
                this.form._id = id;

                const reqEmpresa = await this.$http.get(`/v1/empresas/${this.$store.state.empresa._id}`);

                if(reqEmpresa.data && reqEmpresa.data._id){
                    this.form.assuntoDoConvite = reqEmpresa.data.emailsConfig.treinamentoConvite.titulo;
                    this.form.textoDoConvite = reqEmpresa.data.emailsConfig.treinamentoConvite.descricao;
                }
            }
        }
    },
}
</script>
